<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="add_crminvoice" aria-labelledby="sidebar-no-header-title" no-header backdrop width="90rem" :style="`direction:`+lang.ldir" right :title="lang.add_invoice" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{lang.add_invoice}}</span>
        </div>
        <div @click="hide" id="HideMe" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row v-if="$store.state.licenseType.cars || $store.state.licenseType.bill">
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        @change="getName()"
                        >{{ customer.customerid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="8" xs="12">
                    <v-text-field
                        :label="lang.customer_name"
                        v-model="customer.full_name"
                        id="CusMobile"
                        >{{ customer.full_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="customer.cctype"
                        :items="customer.ctype"
                        :label="lang.customer_type"
                        @change="changeCtype()"
                        ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.licenseType.parts">
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        @change="getName()"
                        >{{ customer.customerid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="lang.mobile"
                        v-model="customer.mobile"
                        :rules="fieldRules"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="8" xs="12">
                    <v-text-field
                        :label="lang.customer_name"
                        v-model="customer.full_name"
                        :rules="fieldRules"
                        >{{ customer.full_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="lang.model"
                        v-model="car.model"
                        :rules="fieldRules"
                        >{{ car.model }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="customer.cctype"
                        :items="customer.ctype"
                        :label="lang.customer_type"
                        @change="changeCtype()"
                        ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="showcamp">
                <v-col cols="12" md="2" sm="12" xs="12" v-if="$store.state.licenseType.showproject">
                    <v-text-field
                        :label="lang.projectname"
                        v-model="company.projectname"
                        >{{ company.projectname }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="12" xs="12">
                    <v-text-field
                        :label="lang.company_name"
                        v-model="company.fullname"
                        >{{ company.fullname }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="12" xs="12">
                    <v-text-field
                        :label="lang.comapny_address"
                        v-model="company.address"
                        >{{ company.address }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <v-text-field
                        :label="lang.company_vatid"
                        v-model="company.vatid"
                        >{{ company.fullname }}</v-text-field>
                </v-col>
            </v-row>
            <v-row  v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="lang.plate_number"
                        v-model="car.plate_number" 
                    >{{ car.plate_number }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="lang.car_model"
                        v-model="car.model"
                        >{{ car.model }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <v-text-field
                        :label="lang.car_year"
                        v-model="car.made_year"
                        >{{ car.made_year }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <v-text-field
                        :label="lang.car_color"
                        v-model="car.color"
                        >{{ car.color }}</v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.invtype"
                        :items="invtypes"
                        :label="lang.invoice_type"
                        @change="InvType()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.vtype"
                        :items="vattype"
                        :label="lang.vat_type"
                        @change="changePtype()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.paytype"
                        :items="paytypes"
                        :label="lang.payment_method"
                        @change="changePtype()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.userid"
                        :items="invoice.users"
                        :label="lang.saler_name"
                        @change="changePtype()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <v-text-field
                            v-model="invoice.date"
                            :label="lang.invoice_date"
                            prepend-icon="mdi-calendar"
                            readonly
                        >{{ invoice.date }}</v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="8" xs="12">
                    <v-textarea
                        :label="lang.invoice_notes"
                        v-model="invoice.notes"
                        >{{ invoice.notes }}</v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-window>{{lang.invoice_details}}</v-window>
                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>{{lang.item_code}}</th>
                                <th style="width:35%" class="text-center">{{lang.description}}</th>
                                <th class="text-center">{{lang.qtty}}</th>
                                <th class="text-center">{{lang.item_price}}</th>
                                <th class="text-center">{{lang.total}}</th>
                                <th class="text-center">{{lang.vat}}</th>
                                <th class="text-center">{{lang.ftotal}}</th>
                            </tr>
                        </thead>
                        <tbody id="tablerow">
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td>{{ item.itemcode }}</td>
                                <td>{{ item.item_name }}</td>
                                <td>{{ item.qty }}</td>
                                <td>{{ item.item_price }}</td>
                                <td>{{ item.total }}</td>
                                <td>{{ item.vat }}</td>
                                <td>{{ item.ftotal }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"> % {{lang.discount}}</th>
                                <th class="text-center">
                                    <v-text-field
                                        v-model="invoice.discount"
                                        @change="calcAll()"
                                        type="number"
                                    ></v-text-field>
                                </th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center">{{lang.total}}</th>
                                <th class="text-center">{{ full_total }}</th>
                                <th class="text-center">{{ full_vat }}</th>
                                <th class="text-center">{{ full_ftotal }}</th>
                                <th class="text-center"></th>
                            </tr>
                        </tfoot>
                        
                    </template>
                </v-simple-table>
            </v-row>
            <v-row>
                <v-col cols="12" md="4" sm="6" xs="12">
                    <v-text-field
                        :label="lang.cash_payment"
                        v-if="invoice.paytype == 1 || invoice.paytype == 3"
                        v-model="invoice.payment.cash"
                        type="number"
                        >{{ invoice.payment.cash }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6" xs="12">
                    <v-text-field
                        v-if="invoice.paytype == 2 || invoice.paytype == 3"
                        :label="lang.span_payment"
                        v-model="invoice.payment.span"
                        type="number"
                        >{{ invoice.payment.span }}</v-text-field>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="addInvoice()" id="addBTN" variant="success" class="ma-2 btn-sm" style="width:130px;">{{lang.create_invoice}}</b-button>
        </div>
      
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>

<script>
import axios from 'axios'
export default{
    props:[
        'custom','prods','cardid'
    ],
    data() {
        return {
            tbrows:[],
            products: [],
            payby: 1,
            itemsworkdes:[
                
            ],
            car: {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            },
            modal: false,
            full_total: 0,
            full_vat: 0,
            full_ftotal: 0,
            showcamp: false,
            company:{
                fullname: '',
                vatid: '',
                projectname: ''
            },
            customer: {
                id:0,
                mobile: '',
                full_name: '',
                customerid: '',
                cctype: 1,
                ctype: [
                    {
                        text: 'عميل فردي',
                        value: 1,
                    },
                    {
                        text: 'عميل شركة',
                        value: 2,
                    }
                ]
            },
            invoice:{
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: 0,
                    span: 0
                },
                billid: 1,
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                
                invtype:2,
                
                paytype: 2,
                
                userid: 0,
                users: [

                ],
                notes: '',
            },
            fieldRules: [
                v => !!v || this.lang.required_field,
            ],
            
            newrow:{
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            },
        }
    },
    created() {
        this.getInvSettings();
        // this.getInfo();
        // setTimeout(() => {this.getName();},1000)
        setInterval(() => {this.calcAll()} , 5000);
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        paytypes: function(){
            return [
                {text: this.lang.cash_payment, value: 1},
                {text: this.lang.span_payment, value: 2},
                {text: this.lang.bank_payment, value: 3},
                {text: this.lang.multi_payments, value: 3},
            ]
        },
        invtypes: function() {
            return [
                    {
                        text: this.lang.none_paid_invoice,
                        value: 2,
                    },
                    {
                        text: this.lang.paid_invoice,
                        value: 1,
                    },
                ]
        },
        vattype: function(){
            return [
                {
                    text: this.lang.prices_include_vat,
                    value: 3,
                },
                {
                    text: this.lang.prices_exclusive_vat,
                    value: 2,
                },
                {
                    text: this.lang.prices_without_vat,
                    value: 1,
                },
            ]
        },
        
    },
    methods: {
        tbrowsss(){
            let t = [];
            if(typeof this.products === 'undefined'){
                return t;
            }
            if(this.products.length != 0){
                for(let i = 0;i<this.products.length;i++){
                    t.push(
                        {
                            itemcode: '',
                            item_name: this.products[i].name,
                            qty: this.products[i].qty,
                            item_price: this.products[i].price,
                            total: this.products[i].total,
                            ftotal: this.products[i].ftotal,
                            vat: this.products[i].vat,
                        }
                    );
                }
            }
            this.tbrows = t;
        },
        getInfo(){
            this.customer.mobile = this.crminfo.mobile;
            this.getName();
        },
        resetInvoice(){
            this.payby = 1;
            this.itemsworkdes = [
                
            ];
            this.car = {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            };
            this.modal = false;
            this.full_total =  0;
            this.full_vat =  0;
            this.full_ftotal =  0;
            this.showcamp =  false;
            this.company = {
                fullname: '',
                vatid: '',
                address: '',
            };
            this.customer.id = 0;
            this.customer.mobile = '';
            this.customer.full_name = '';
            this.customer.customerid = '';
            this.customer.cctype = '';
            this.invoice.payment.cash = 0;
            this.invoice.payment.span = 0;
            this.invoice.payment.discount = 0;
            this.invoice.payment.vtype = 0;
            this.invoice.payment.t_discount = 0;
        },
        addInvoice(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.forinv.validate()){
                return false;
            }
            if(this.customer.mobile == '') {
                this.customer.mobile = 1234567890;
            }
            if(this.customer.full_name == '') {
                this.customer.full_name = this.lang.individual_client;
            }
            if(this.tbrows.length == 0){
                this.$snotify.error(this.lang.you_can_not_add_empty_invoice, this.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
           if(this.customer.cctype == 2 && this.company.fullname == ''){
               this.$snotify.error(this.lang.company_ifnormation_required, this.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
           }
            document.getElementById('addBTN').disabled = true;
            const post = new FormData();
            post.append("type" , "addInvoice"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i = 0; i < this.tbrows.length;i++){
                for(const [key, value] of Object.entries(this.tbrows[i])){
                    post.append('data[tbrows]['+i+']['+key+']]',value);
                }
            }
            for(const [key, value] of Object.entries(this.car)){
                post.append('data[car]['+key+']]',value);
            }
            post.append("data[inovice][date]",this.invoice.date);
            post.append("data[inovice][itemcode]",0);
            post.append("data[inovice][payment][cash]",this.invoice.payment.cash);
            post.append("data[inovice][payment][span]",this.invoice.payment.span);
            post.append("data[inovice][billid]",this.invoice.billid);
            post.append("data[inovice][vtype]",this.invoice.vtype);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][paytype]",this.invoice.paytype);
            post.append("data[inovice][userid]",this.invoice.userid);
            post.append("data[inovice][full_total]",this.full_total);
            post.append("data[inovice][full_vat]",this.full_vat);
            post.append("data[inovice][full_ftotal]",this.full_ftotal);
            post.append("data[inovice][t_discount]",this.invoice.t_discount);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][cash]",this.invoice.payment.cash);
            post.append("data[inovice][span]",this.invoice.payment.span);
            post.append("data[inovice][notes]",this.invoice.notes);
            post.append("data[inovice][projectname]",this.company.projectname);
            post.append("data[cardid]",0);
            post.append("data[crmid]",this.$parent.orderid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                // // console.log(resposne.data);
                document.getElementById('addBTN').disabled = false;
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                this.$snotify.success(this.lang.invoice_added, this.lang.add_invoice, {
                    timeout: 2000,
                    showProgressBar: true, 
                    closeOnClick: false,
                    pauseOnHover: true,
                    leftTop:"leftTop"
                });
                this.getOrder();
                this.printPDF(resposne.data.results.data.results.newID);
                
            }).then(() => {
                document.getElementById('HideMe').click();
            })

        },
        printInvo(id){
            window.open('../api/print.php?invid='+id,'_blank');
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        changePtype(){
            let tbs = [];
             if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    let fftotal = parseFloat(this.tbrows[i].item_price) * parseFloat(this.tbrows[i].qty);
                    fftotal = this.$RoundNum(fftotal);
                    const vs = this.$calcVat(fftotal,this.invoice.vtype);
                    const itm = {
                        item_name: this.tbrows[i].item_name,
                        qty: this.tbrows[i].qty,
                        item_price: this.tbrows[i].item_price,
                        total: vs.tot,
                        vat: vs.vat,
                        ftotal: vs.ftot
                    }
                    tbs.push(itm);
                }
            }
            this.tbrows = tbs;
            this.calcAll();
            this.calcKit();
        },
        calcAll(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;
            if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    this.full_total = parseFloat(this.full_total) + parseFloat(this.tbrows[i].total);
                    this.full_vat = parseFloat(this.full_vat) + parseFloat(this.tbrows[i].vat);
                    this.full_ftotal = parseFloat(this.full_ftotal) + parseFloat(this.tbrows[i].ftotal);
                }
            }
            if(this.invoice.discount > 30){
                this.invoice.discount = 30;
            }
            if(this.invoice.discount != 0){
                this.invoice.t_discount = parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_total = parseFloat(this.full_total) - parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_vat = parseFloat(this.full_vat) - parseFloat(this.invoice.discount) * parseFloat(this.full_vat) / parseFloat(100);
                this.full_ftotal = parseFloat(this.full_ftotal) - parseFloat(this.invoice.discount) * parseFloat(this.full_ftotal) / parseFloat(100);
            }
            this.full_total = this.$RoundNum(this.full_total);
            this.full_vat = this.$RoundNum(this.full_vat);
            this.full_ftotal = this.$RoundNum(this.full_ftotal);
            if(this.invoice.paytype == 1 || this.invoice.paytype == 3){
                this.invoice.payment.cash = this.full_ftotal;
                this.invoice.payment.span = 0;
            }else if(this.invoice.paytype == 2){
                this.invoice.payment.cash = 0;
                this.invoice.payment.span = this.full_ftotal;
            }
            if(this.invoice.invtype == 2){
                this.invoice.payment.cash = 0;
                this.invoice.payment.span = 0;
            }
        },
        InvType () {
            if(this.invoice.invtype == 1){
                if(this.paytype == 1){
                    this.invoice.payment.cash = this.full_ftotal;
                    this.payment.span = 0;
                }else{
                    this.invoice.payment.span = this.full_ftotal;
                    this.invoice.payment.cash = 0;
                }
            }else{
                this.invoice.payment.span = 0;
                this.invoice.payment.cash = 0;
            }
        },
        removeItem(item){
            this.tbrows.splice(item, 1);
            this.calcAll()
        },
        calcKit() {
            const vt = this.invoice.vtype;
            const price = +this.newrow.item_price * +this.newrow.qty;
            const vat = this.$calcVat(price,vt);
            this.newrow.total = this.$RoundNum(vat.tot);
            this.newrow.ftotal = this.$RoundNum(vat.ftot);
            this.newrow.vat = this.$RoundNum(vat.vat);
        },
        addNewRow() {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow.item_name == ''){
                this.$snotify.error(this.lang.can_not_add_item_withoud_discription, this.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
            if(this.newrow.qty == '' || this.newrow.qty == 0){
                this.$snotify.error(this.lang.item_qtty_must_be_more_than_zero, this.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
            if(!this.$store.state.zeroPrice){

                if(this.newrow.item_price == '' || this.newrow.item_price == 0){
                    this.$snotify.error(this.lang.item_price_must_be_more_than_zero, this.lang.add_invoice, {
                            timeout: 2000,
                            showProgressBar: true,
                            closeOnClick: false,
                            pauseOnHover: true,
                            leftTop:"leftTop"
                        });
                    return false;
                }
            }
            this.tbrows.push(this.newrow);
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0
            }
            this.calcAll()
        },
        changeCtype() {
            if(this.customer.cctype == 2){
                this.showcamp = true;
            }else{
                this.showcamp = false;
            }
        },
        getInvSettings() {
            const post = new FormData();
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.desk.length != 0){
                            this.itemsworkdes = [];
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.desk.length; i++){
                                this.itemsworkdes.push(res.results.data.desk[i].title);
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.products.length; i++){
                                this.itemsworkdes.push(res.results.data.products[i].itemcode + ' - ' + res.results.data.products[i].name);
                            }
                        }
                    }
                }
            )
        },
        getName(){
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.crminfo.mobile);
            post.append('data[customerid]',this.customer.customerid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    // // console.log("asdfasdf",res);
                    
                        // this.customer.id = res.results.data['id'];
                        if(this.crminfo.invid == 0 && this.crminfo.products.length > 1){
                            this.customer.mobile = res.results.data['mobile'];
                            this.customer.full_name = res.results.data['full_name'];
                            this.customer.customerid = res.results.data['customerid'];
                            this.company.fullname = res.results.data['company_name'];
                            this.company.vatid = res.results.data['company_vatid'];
                        }
                    
                }
            )
        }
    },
}
</script>